import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Language } from '@shared/models/language.model';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private _languages: Language[] = [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' }
  ];

  get languages() {
    return this._languages;
  }

  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  setLocale(lang: string) {
    this.translate.use(lang);
  }
}
