export const ENDPOINTS = {
  assets: '/assets',
  organizations: '/organizations',
  profiles: '/profiles',
  public: '/public',
  roles: '/roles',
  tags: '/tags',
  templates: '/templates',
  verify: '/auth/verify'
};

export const INPUT_ACCEPT = {
  asset: '.glb,.jpeg,.jpg,.mp3,.mp4,.png',
  audio: '.mp3',
  image: '.jpeg,.jpg,.png',
  model: '.glb',
  video: '.mp4'
};

export const NO_FILE = 'asset.management.no-file';

export const REGEX = {
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
};
