import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ProfileOrganizationViewModel, ProfileViewModel } from '../models/profile.model';
import { Result } from '@shared/models/result.model';
import { ENDPOINTS } from '@shared/util/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get<Result<ProfileViewModel | undefined>>(
      `${environment.api}${ENDPOINTS.profiles}/${id}`
    );
  }

  getOrganization(profile: string, organization: string) {
    return this.http.get<Result<ProfileOrganizationViewModel | undefined>>(
      `${environment.api}${ENDPOINTS.profiles}/${profile}${ENDPOINTS.organizations}/${organization}`
    );
  }
}
