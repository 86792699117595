import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private background = '#0091ff';

  constructor(private translate: TranslateService) {}

  close() {
    Swal.close();
  }

  confirm(html: string, next: () => void) {
    const alert = Swal.fire({
      title: this.span(this.translate.instant('common.alert'), 'poppins-bold', '#212138'),
      html: this.span(html, 'poppins-regular', '#212138'),
      icon: 'question',
      confirmButtonText: this.span(this.translate.instant('common.ok'), 'poppins-bold', '#fff'),
      confirmButtonColor: this.background,
      showCancelButton: true,
      cancelButtonText: this.span(
        this.translate.instant('common.cancel'),
        'poppins-bold',
        '#212138'
      ),
      cancelButtonColor: '#e9eaf0'
    }).then(result => {
      if (result.isConfirmed) next();
    });
  }

  create(options: { title?: string; html?: string; icon?: SweetAlertIcon }) {
    Swal.fire({
      title: options.title ? this.span(options.title, 'poppins-bold', '#212138') : undefined,
      html: options.html
        ? `<div style="font-family: poppins-regular; color: #212138; text-align:left">${options.html}</div>`
        : undefined,
      icon: options.icon,
      confirmButtonText: this.span(this.translate.instant('common.ok'), 'poppins-bold', '#fff'),
      confirmButtonColor: this.background
    });
  }

  error(html: string) {
    Swal.fire({
      title: this.span(this.translate.instant('common.alert'), 'poppins-bold', '#212138'),
      html: this.span(html, 'poppins-regular', '#212138'),
      icon: 'error',
      confirmButtonText: this.span(this.translate.instant('common.ok'), 'poppins-bold', '#fff'),
      confirmButtonColor: this.background
    });
  }

  info(html: string) {
    Swal.fire({
      title: this.span(this.translate.instant('common.alert'), 'poppins-bold', '#212138'),
      html: this.span(html, 'poppins-regular', '#212138'),
      icon: 'info',
      confirmButtonText: this.span(this.translate.instant('common.ok'), 'poppins-bold', '#fff'),
      confirmButtonColor: this.background
    });
  }

  loading() {
    Swal.fire({
      title: this.span(this.translate.instant('common.loading'), 'poppins-bold', '#212138'),
      didOpen: () => {
        Swal.showLoading();
      },
      allowEscapeKey: false,
      allowOutsideClick: false
    });
  }

  media(html: string) {
    Swal.fire({
      html: `<div style="display: flex; justify-content: center; align-items: center;">${html}</div>`,
      confirmButtonText: this.span(this.translate.instant('common.ok'), 'poppins-bold', '#fff'),
      confirmButtonColor: this.background
    });
  }

  private span(html: string, font: 'poppins-bold' | 'poppins-regular', color: '#212138' | '#fff') {
    return `<span style="font-family: ${font}; color: ${color}">${html}<span>`;
  }
}
