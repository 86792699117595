import { FormGroup } from '@angular/forms';

export const clearLocalStorage = () => {
  const preservedKeys = ['lang', 'view'];
  const keys = Object.keys(localStorage);

  keys.forEach(key => {
    if (preservedKeys.includes(key)) return;
    localStorage.removeItem(key);
  });
};

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};

export const stringToDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(+year, +month - 1, +day);
};

export const urlToFile = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export const validateForm = (group: FormGroup) => {
  const errors: any = {};

  for (const key in group.controls) {
    if (!Object.prototype.hasOwnProperty.call(group.controls, key)) continue;

    const control = group.controls[key];
    if (!control.invalid) continue;

    control.markAsDirty();
    control.updateValueAndValidity({ onlySelf: true });
    errors[key] = control.errors;
  }

  return errors;
};
